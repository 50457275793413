
<template>
  <div class="MatcCanvas MatcAnalyticCanvas">
    <div class="MatcCanvasFrame" data-dojo-attach-point="frame">
      <div class="MatcCanvasContainer MatcCanvasZoomable"  data-dojo-attach-point="container" >
        <div class="MatcCanvasContainer" data-dojo-attach-point="zoomContainer">
          <div  data-dojo-attach-point="screenContainer" class="MatcCanvasLayer" ></div>
          <div data-dojo-attach-point="widgetContainer" class="MatcCanvasLayer"></div>
					<div data-dojo-attach-point="svgContainer" class="MatcCanvasLayer MatcCanvasSVGLayer"></div>
        </div>
        <div data-dojo-attach-point="dndContainer" class="MatcDnDLayer"></div>
      </div>
    </div>
    <div class="MatcCanvasScrollBar MatcCanvasScrollBarRight" data-dojo-attach-point="scrollRight">
      <div class="MatcCanvasScrollBarCntr MatcCanvasScrollBarCntrRight" data-dojo-attach-point="scrollRightCntr" >
        <div class="MatchCanvasScrollHandle" data-dojo-attach-point="scrollRightHandler"
        ></div>
      </div>
    </div>
    <div class="MatcCanvasScrollBar MatcCanvasScrollBarBottom" data-dojo-attach-point="scrollBottom" >
      <div class="MatcCanvasScrollBarCntr MatcCanvasScrollBarCntrBottom" data-dojo-attach-point="scrollBottomCntr" >
        <div class="MatchCanvasScrollHandle" data-dojo-attach-point="scrollBottomHandler"
        ></div>
      </div>
    </div>
    <div class="MatcMessage" data-dojo-attach-point="message"></div>
  </div>
</template>

<script>
import DojoWidget from "dojo/DojoWidget";
import css from "dojo/css";

import Logger from "common/Logger";
import on from "dojo/on";
import touch from "dojo/touch";
import lang from "dojo/_base/lang";

import win from "dojo/_base/win";
import topic from "dojo/topic";

import DomBuilder from "common/DomBuilder";
import DataFrame from "common/DataFrame";
import _DragNDrop from "common/_DragNDrop";

import Heat from "dash/Heat";
import Render from "canvas/Render";
import Lines from "canvas/Lines";
import DnD from "canvas/DnD";
import Add from "canvas/Add";
import Select from "canvas/Select";
import Distribute from "canvas/Distribute";
import Tools from "canvas/Tools";
import Zoom from "canvas/Zoom";
import Util from "core/Util";
import InlineEdit from "canvas/InlineEdit";
import Scroll from "canvas/Scroll";
import Upload from "canvas/Upload";
import Comment from "canvas/Comment";
import KeyBoard from "canvas/KeyBoard";
import Resize from "canvas/Resize";
import Replicate from "canvas/Replicate";
import Analytics from "dash/Analytics";
import FastDomUtil from "core/FastDomUtil";
import * as d3 from "d3";
import _Color from 'common/_Color'

export default {
  name: "AnalyticCanvas",
  mixins: [
    DojoWidget,
    _DragNDrop,
    _Color,
    Util,
    Render,
    Lines,
    DnD,
    Add,
    Select,
    Distribute,
    Tools,
    Zoom,
    InlineEdit,
    Scroll,
    Upload,
    KeyBoard,
    Resize,
    Replicate,
    Comment,
    Heat,
  ],
  data: function () {
    return {
      mode: "view",
      zoom: 0.5,
      analyticMode: "HeatmapClick",
      resizeEnabled: false,
      renderDND: true,
      dragNDropMinTimeSpan: 0,
      wireInheritedWidgets: true,
      taskLineOpacity: 1,
      isBlackAndWhite: false,
      dropOffLineWidth: 25,
      dropOffLineColor: '#555',
      dropOffEventWidth: 40,
      userJourneyEndColor: '#f03131'
    };
  },
  components: {},
  methods: {
    postCreate() {
      this.logger = new Logger("AnalyticCanvas");
      this.logger.log(2, "constructor", "entry");
      this.cache = {};
      this.moveMode = "classic";
      this.domUtil = new FastDomUtil();
			this.analyticLines = {}

      this.logger.log(2, "postCreate", "entry");
      this.initSize();

      /**
       * init container size and position
       */
      this.canvasPos = {
        x: this.canvasStartX,
        y: this.canvasStartY,
        w: this.canvasFlowWidth,
        h: this.canvasFlowHeight,
      };
      this.initContainerSize();
      this.setContainerPos();

      /**
       * Init remaining sub components
       */
      this.initRender();
			this.initAnalyticSVG()
      this.initZoom();
      this.initScrollBars();
      this.initComment();
      this.initSettings();
      this.initWiring();
      this.initKeys();

      this.db = new DomBuilder();

      /**
       * Init Listeners
       */
      this.own(
        topic.subscribe(
          "matc/toolbar/click",
          lang.hitch(this, "onToolbarClick")
        )
      );
      this.own(on(win.body(), "keydown", lang.hitch(this, "onKeyPress")));
      this.own(on(win.body(), "keyup", lang.hitch(this, "onKeyUp")));

      this.logger.log(2, "postCreate", "exit!!!");
    },

    showError (msg){
			if(this.message){
				css.add(this.message, "MatcMessageError");
				css.remove(this.message, "MatcMessageSuccess MatcMessageHint");
				this.message.textContent = msg;
				setTimeout(lang.hitch(this,"hideMessage"), 3000);
			}
		},


    XlineFunction (line) {
    	return this.straightLineFunction(line)
    },

    setPublic(isPublic) {
      this.isPublic = isPublic;
    },

    setModelService(s) {
      this.sourceModelService = s;
    },

    setCommentService(s) {
      this.commentService = s;
    },

    setToolbar(t) {
      this.toolbar = t;
      this.onChangeCanvasViewConfig();
    },

		setMouseListener (callback) {
			this.mouseListenerCallback = callback
		},

    inlineEditInit() {
      this.logger.log(2, "inlineEditInit", "enter");
    },

    setMouseData(data) {
      this.logger.log(0, "setMouseData", "enter > " + data.length);
      // this.mouseData = this.computeMouseDistribution(data, this.sourceModel);
      this.mouseData = data;
      if (data.length == 0) {
        this.showError("No Mouse data was recorded");
      }
    },

    setBW(isBW) {
      this.logger.log(-1, "setBW", "enter > " + isBW);
      if (isBW) {
        css.add(this.container, "MatcCanvasBW");
      } else {
        css.remove(this.container, "MatcCanvasBW");
      }
    },

    onChangeCanvasViewConfig() {
      if (this.toolbar) {
        this.toolbar.setCanvasViewConfig({
          zoom: this.zoom,
          renderLines: this.renderLines,
          showComments: this.showComments,
          isBlackAndWhite: this.isBlackAndWhite,
        });
      }
    },

    setCanvasViewConfig(key, value) {
      this.logger.log(-1, "setCanvasViewConfig", "enter > " + key, value);
      if (key === "zoom") {
        this.setZoomFactor(value);
      }

      if (key === "renderLines") {
        this.setViewLines(value);
      }

      if (key === "showComments") {
        this.setCommentView(value);
      }

      if (key === "isBlackAndWhite") {
        this.isBlackAndWhite = value;
        this.setBW(value);
      }
    },

		/**********************************************************************
     * Lines
     **********************************************************************/

		initAnalyticSVG (){
			this.logger.log(3, "initAnalyticSVG", "entry");
			let bodySelection = d3.select(this.svgContainer);
			this.analyticSVG = bodySelection.append("svg").attr("width", this.canvasPos.h).attr("height",this.canvasPos.w);
	  },

		cleanUpAnalyticLines () {
			if (this.analyticSVG) {
				this.analyticSVG.selectAll("*").remove();
			}
			this.analyticLines = {}
		},

		drawAnalyticLine(id, line, color, width, opacity) {
			this.analyticSVG.append("path")
							.attr("d", this.lineFunction(line))
							.attr("stroke", color)
							.attr("stroke-width", width )
							.attr("fill", "none")
							.style("opacity", opacity);

			this.analyticLines[id] = line
		},


		drawStraightAnalyticLine(id, line, color, width, opacity) {
			this.analyticSVG.append("path")
							.attr("d", this.straightLineFunction(line))
							.attr("stroke", color)
							.attr("stroke-width", width )
							.attr("fill", "none")
							.style("opacity", opacity);

			this.analyticLines[id] = line
		},

    

    /**********************************************************************
     * Wiring
     **********************************************************************/

    initWiring() {
      this.logger.log(-1, "initWiring", "enter");
      this.own(on(this.dndContainer, "mousedown", (e) => this.dispatchMouseDown(e)));
    },

    dispatchMouseDownScreen(e, id) {
      this.logger.log(-1, "dispatchMouseDownScreen", "enter", id);
      let dndDiv = this.screenDivs[id];
      let screen = this.model.screens[id];
      this.onScreenDndClick(screen.id, dndDiv, null);
    },

    dispatchMouseDownWidget(e, id) {
      this.logger.log(-1, "dispatchMouseDownWidget", "enter", id);
      let div = this.widgetDivs[id];
      this.onWidgetDndClick(id, div);
    },

    /**********************************************************************
     * Settings
     **********************************************************************/

		afterUpdateDnd (zoomedModel) {
      this.logger.log(1, "afterUpdateDnd", "enter > ", zoomedModel);
		},

    initSettings() {
      this.logger.log(1, "initSettings", "enter > ");
      /**
       * default settings
       */
      this.settings = {
        canvasTheme: "MatcDark",
        lineColor: "#333",
        lineWidth: 1,
        storePropView: true,
        moveMode: "ps",
        mouseWheelMode: "scroll",
      };

      var s = this._getStatus("matcSettings");
      if (s) {
        if (s.canvasTheme) {
          this.settings.canvasTheme = s.canvasTheme;
        }
        if (s.lineColor) {
          this.settings.lineColor = s.lineColor;
        }
        if (s.lineWidth) {
          this.settings.lineWidth = s.lineWidth;
        }
      } else {
        this.logger.log(2, "initSettings", "exit>  no saved settings");
      }

      this.applySettings(this.settings);
    },

    getSettings() {
      return this.settings;
    },

    setSettings(s) {
      /**
       * Mixin values
       */
      if (s.canvasTheme) {
        this.settings.canvasTheme = s.canvasTheme;
      }
      if (s.lineColor) {
        this.settings.lineColor = s.lineColor;
      }
      if (s.lineWidth) {
        this.settings.lineWidth = s.lineWidth;
      }
      if (s.storePropView != null) {
        this.settings.storePropView = s.storePropView;
      }

      if (s.mouseWheelMode != null) {
        this.settings.mouseWheelMode = s.mouseWheelMode;
      }

      this._setStatus("matcSettings", this.settings);

      this.applySettings(this.settings);
      this.rerender();
    },

    applySettings(s) {
      this.logger.log(
        2,
        "applySettings",
        "enter > " + s.canvasTheme + " &> " + s.moveMode
      );

      if (s.lineColor) {
        this.defaultLineColor = s.lineColor;
      }
      if (s.lineWidth) {
        this.defaultLineWidth = s.lineWidth;
      }
      if (s.canvasTheme) {
        if (this._lastCanvasTheme) {
          css.remove(win.body(), this._lastCanvasTheme);
        }
        css.add(win.body(), s.canvasTheme);
        this._lastCanvasTheme = s.canvasTheme;

        /**
         * FIXME: Kind of hack
         */
        if (s.canvasTheme == "MatcLight") {
          this.defaultLineColor = "#777";
        } else {
          this.defaultLineColor = "#333";
        }
      }

      if (s.mouseWheelMode) {
        this._mouseWheelMode = s.mouseWheelMode;
      }

      this.settings = s;
    },

    /**********************************************************************
     * DnD.js overwrites
     **********************************************************************/

    onWidgetDndClick(id, div, pos, e) {
      this.logger.log(2, "onWidgetDndClick", "enter > " + id);
      this.stopEvent(e);
      this.onWidgetSelected(id);
      this.selectAnalyticDiv(id);
      this.setState(0);
    },

    onScreenDndClick(id, div, pos, e) {
      this.logger.log(2, "onScreenDndClick", "entry > " + id);
      this.stopEvent(e);
      this.onScreenSelected(id);
      this.selectAnalyticDiv(id);
      this.setState(0);
    },

    onCanvasSelected() {
      this.logger.log(2, "onCanvasSelected", "entry > ");
      this.selectAnalyticDiv(null);
      if (this.toolbar) {
        this.toolbar.unselect();
        if (this.analyticMode === "HeatmapClick") {
          this.toolbar.reShowClickHeatMap();
        }
      }
    },

    selectAnalyticDiv(id) {
      if (this._analyticLastSelectedDiv) {
        css.remove(this._analyticLastSelectedDiv, "MatcHeapMapWidgetSelected");
        delete this._analyticLastSelectedDiv;
      }
      if (this.analyticsDivs && this.analyticsDivs[id]) {
        let div = this.analyticsDivs[id];
        css.add(div, "MatcHeapMapWidgetSelected");
        this._analyticLastSelectedDiv = div;
      }

      if (this.widgetDivs && this.widgetDivs[id]) {
        let div = this.widgetDivs[id];
        css.add(div, "MatcHeapMapWidgetSelected");
        this._analyticLastSelectedDiv = div;
      }
    },

    /**********************************************************************
     * Rendering
     **********************************************************************/

    renderLayerList() {
      this.logger.log(-1, "renderLayerList", "entry > ");
    },

    afterRender() {
      this.logger.log(-1, "afterRender", "entry > " + this.analyticMode);
      this.cleanUpAnalytics();

      try {
        this._renderHeatMap();
      } catch (e) {
        this.logger.error("afterRender", "Could not render heatmaps ", e);
        this.logger.sendError(e);
      }
    },

    hasSelect() {
      return this.mode != "addComment";
    },

    _renderHeatMap() {
      this.logPageView("/analytics/workspace/" + this.analyticMode + ".html");

      this.setBW(this.isBlackAndWhite);

      /**
       * Init everything so the _Heat.js code works correctly
       */
      this.cleanUpHeat();

      /**
       * FIXME: Make this customisable
       */
      if (
        this.sourceModel.type == "smartphone" ||
        this.sourceModel.type == "tablet"
      ) {
        this.defaultRadius = this.sourceModel.screenSize.w / 20;
        this.defaultBlur = this.sourceModel.screenSize.w / 15;
      } else {
        this.defaultRadius = this.sourceModel.screenSize.w / 120;
        this.defaultBlur = this.sourceModel.screenSize.w / 100;
      }

      this.logger.log(0,"onScreenRendered", "adjust radios to " + this.defaultRadius);

      var screenGrouping = this.df.groupBy("screen");

      this.heatmapDivs = {};
      for (var id in this.sourceModel.screens) {
        var screen = this.sourceModel.screens[id];

        var screenDF = screenGrouping.get(id);
        var screenEvents = [];
        if (screenDF) {
          screenEvents = screenDF.as_array();
        }

        if (this["_render_" + this.analyticMode]) {
          /**
           * create canvas
           */
          var div = this.createBox(screen);
          css.add(div, "MatcHeatMapScreen");
          var cntr = this.db.div("MatcHeapMapContainer").build(div);

          var canvas = this.db.canvas(screen.w, screen.h).build(cntr);
          var ctx = canvas.getContext("2d");

          this["_render_" + this.analyticMode](screenEvents, screen, ctx, div);

          if (this.hasSelect()) {
            this.tempOwn(on(div,touch.press,lang.hitch(this, "onScreenDndClick", screen.id, div, null)));
          }

          this.widgetContainer.appendChild(div);

          this.heatmapDivs[screen.id] = div;
          this.analyticsDivs[screen.id] = div;
        }
      }

      /**
       * now draw a div for every widgert so we can also select them.
       * A little hack but I dunno have a better way...
       */
      if ("UserJourney" != this.analyticMode && "Gesture" != this.analyticMode && 'DropOff' != this.analyticMode) {
        this.hideWidgetDND = true;
      } else {
        this.hideWidgetDND = false;
      }

      if (this["_render_global_" + this.analyticMode]) {
        this["_render_global_" + this.analyticMode](screenEvents,screen, ctx, div);
      }
    },

    _render_HeatmapMouse(screenEvents, screen, ctx) {
      this.logger.log(0, "_render_HeatmapMouse", "entry > " + screen.name);
      /**
       * FIXME: we could make this fastter by caching some stuff,
       * or at least soft the events by screen
       */
      let mouseData = this.mouseData.filter((m) => m.screen === screen.id);
      let data = this.computeMouseDistribution(mouseData, this.sourceModel);
      if (data[screen.id]) {
        let d = data[screen.id];
        this.draw(ctx, d.values, d.max, screen.w, screen.h);
      }
    },

    _render_HeatmapClick(screenEvents, screen, ctx, div) {
      this.logger.log(2, "_render_HeatmapClick", "entry > ");

      var numberOfClicks = -1;
      if (this.analyticParams) {
        numberOfClicks = this.analyticParams.numberOfClicks;
      }

      if (numberOfClicks === "screenClicks") {

        let screenClicks = this.getScreenClicksOnBackground();
        screenClicks = screenClicks.as_array();
        this._render_pixel_screen_heatmap(screenClicks, screen, ctx, div);

      } else if (numberOfClicks === "missedClicks") {

        let missedClicks = this.getMissedClicks();
        this._render_pixel_screen_heatmap(missedClicks, screen, ctx, div);

      } else if (numberOfClicks > 0) {

        let firstNEvents = this.getFirstNClicksData(numberOfClicks);
        this._render_pixel_screen_heatmap(firstNEvents, screen, ctx, div);

      } else {

        /**
         * Ignore Hover events...
         */
        let filtered = this.getClickEvents(new DataFrame(this.events));
        let actionEvents = filtered.as_array();
        this._render_pixel_screen_heatmap(actionEvents, screen, ctx, div);
        
      }
    },

    _render_pixel_screen_heatmap(actionEvents, screen, ctx) {
      const events = [];
      for (let i = 0; i < actionEvents.length; i++) {
        const e = actionEvents[i];
        const screenID = this.getEventScreenId(e);
        if (screenID == screen.id) {
          events.push(e);
        }
      }
      const dist = this.computeClickDistribution(events, screen.w, screen.h);
      this.draw(ctx, dist.values, dist.max, screen.w, screen.h);
    },

    _render_HeatmapScrollView(screenEvents, screen, ctx) {
      this.logger.log(2, "_render_HeatmapScrollView", "entry > ");

      var dist = this.computeScrollVisibiltyDistribution(
        screenEvents,
        this.sourceModel.screenSize.h,
        screen.h
      );
      this.drawSections(dist, ctx, screen.h, screen.w);
    },

    _render_HeatmapScrollTime(screenEvents, screen, ctx) {
      this.logger.log(2, "_render_HeatmapScrollTime", "entry > ");

      var dist = this.computeScrollDurationDistrubtion(
        screenEvents,
        this.sourceModel.screenSize.h,
        screen.h
      );
      this.drawSections(dist, ctx, screen.h, screen.w);
    },

    _render_HeatmapViews(screenEvents, screen, ctx) {
      this.logger.log(2, "HeatmapViews", "entry > ");

      if (screen.style.overlay) {
        let screenViews = this.getOverlayViews();
        let count = screenViews.counts[screen.id];
        if (!count) {
          count = 0;
        }

        ctx.globalAlpha = 0.4;
        let color = this.mixColor(count / screenViews.total);
        ctx.fillStyle = color;
        ctx.fillRect(0, 0, screen.w, screen.h);
      } else {
        let screenViews = this.getScreenViews();
        let count = screenViews.counts[screen.id];
        if (!count) {
          count = 0;
        }

        ctx.globalAlpha = 0.4;
        let color = this.mixColor(count / screenViews.total);
        ctx.fillStyle = color;
        ctx.fillRect(0, 0, screen.w, screen.h);
      }
    },

    _render_HeatmapDwelTime(screenEvents, screen, ctx) {
      this.logger.log(2, "HeatmapDwelTime", "entry > ");

      if (screen.style.overlay) {
        let times = this.getOverlayDwellTime();
        let time = times.times[screen.id];
        if (!time) {
          time = 0;
        }
        ctx.globalAlpha = 0.4;
        let color = this.mixColor(time / times.total);
        ctx.fillStyle = color;
        ctx.fillRect(0, 0, screen.w, screen.h);
      } else {
        let times = this.getScreenDwellTime();
        let time = times.times[screen.id];
        if (!time) {
          time = 0;
        }
        ctx.globalAlpha = 0.4;
        let color = this.mixColor(time / times.total);
        ctx.fillStyle = color;
        ctx.fillRect(0, 0, screen.w, screen.h);
      }
    },

    _render_global_UserJourney(screenEvents, screen, ctx, div) {
      this.logger.log(-1, "_render_global_UserJourney", "entry > ");
      this.setBW(true);
      if (!this.analyticParams.tree) {
        this._renderUserSingleLines(screenEvents, screen, ctx, div);
      } else {
        this._renderUserTree(screenEvents, screen, ctx, div);
      }
    },

    _renderUserTree() {
      let sessions = this.getUserJourney();
      let db = new DomBuilder();
      let time = this.analyticParams.time
      
      let selectedSessions = this.analyticParams.sessions;
      let graph = {};
    

      for (let sessionID in selectedSessions) {
        if (selectedSessions[sessionID] === true) {
          let session = sessions[sessionID];
          if (session) {
            this._getSessionGraph(session, graph, time);
            maxCount++;
          } else {
            console.debug("_renderUserTree() > No session for ", sessionID);
          }
        }
      }

      /**
       * We might have the situation that the users creates loopes
       * in one session. This will cause the count to be bigger than the
       * session count (maxCount). This messes up the graph. To make it
       * nice again, we update maxCount
       */
      let maxCount = 0;
      let maxMeanDuration = 0
      for (let id in graph) {
        const l = graph[id]
        if (l.count > maxCount) {
          console.warn("_renderUserTree() > Update maxcount, because l.count bigger than max count", )
          maxCount = l.count
        }
        const meanDuration = l.duration / l.count
        maxMeanDuration = Math.max(meanDuration, maxMeanDuration)
      }

      const divs = {};
      for (let id in graph) {
        const l = graph[id];
        const line = [];
        line.push({
          x: l.from.x,
          y: l.from.y,
          d: "right",
        });
        line.push({
          x: l.to.x,
          y: l.to.y,
          d: "right",
        });

        const meanDuration = l.duration / l.count
        const p = time ?
            Math.min(1, meanDuration / maxMeanDuration):
            Math.min(1, l.count / maxCount)

        const width = Math.min(15, Math.max(1, Math.round(p * 25))) + 1;
        const color = this.mixColor(Math.min(1, p));
        const toID = l.to.x + "," + l.to.y;
        const fromID = l.from.x + "," + l.from.y;
        
        if (!divs[toID]) {
          divs[toID] = this._renderTreeEvent(l.to.x, l.to.y, width, color, db);
        }
      
        if (!divs[fromID]) {
          divs[fromID] = this._renderTreeEvent(l.from.x,l.from.y,width,color,db);
        }

        this.drawAnalyticLine(id, line, color, width, this.taskLineOpacity);
				this.analyticLines[id] = line
      }
    },



    _renderTreeEvent(x, y, width, color, db) {
      var cntr = db
        .div("MatcAnalyticCanvasEventCntr")
        .build(this.widgetContainer);
      cntr.style.left = Math.round(x) + "px";
      cntr.style.top = Math.round(y) + "px";

      var div = db
        .div("MatcAnalyticCanvasEvent MatcAnalyticCanvasEvent")
        .build(cntr);

      var r = Math.round(width * 2);
      div.style.width = r + "px";
      div.style.height = r + "px";
      div.style.top = -1 * Math.round(r / 2) + "px";
      div.style.left = -1 * Math.round(r / 2) + "px";
      div.style.background = color;
    },

    _renderUserSingleLines() {
      const sessions = this.getUserJourney();
      const taskPerformance = this.getTaskPerformance();
      const db = new DomBuilder();

      let task = null;
      if (this.analyticParams.task !== false && this.analyticParams.task >= 0) {
        task = this.testSettings.tasks[this.analyticParams.task];
      }

      const selectedSessions = this.analyticParams.sessions;
      for (let sessionID in selectedSessions) {
        if (selectedSessions[sessionID] === true) {
          const session = sessions[sessionID];
          const matches = taskPerformance[sessionID];
          if (session) {
            this._renderUserGraph(session, db, task, matches);
          } else {
            console.debug( "_render_global_UserJourney() > No session for ", sessionID   );
          }
        }
      }
    },

    _renderUserGraph(session, db, task, matches) {
      const sessionEvents = session.data;
      const line = [];
      const sessionLength = sessionEvents.length
      const matchLines = [];
    
      let e
      let lastDurationEvent
      let duration = 0
      let maxDuration = 0
      let match;
      if (task && matches) {
        match = matches[task.id];
      }

      // compute line
      for (let i = 0; i < sessionLength; i++) {
        e = sessionEvents[i];
        if (lastDurationEvent) {
          duration = e.time - lastDurationEvent.time
          maxDuration = Math.max(duration, maxDuration)
        }
        
        const screenID = this.getEventScreenId(e);
        const sourceScreen = this.sourceModel.screens[screenID];
				const zoomedScreen = this.model.screens[screenID];
        if (sourceScreen && zoomedScreen) {
          if (e.type == "SessionStart") {
            const x = sourceScreen.x - Math.max(10, Math.round(30 * this.zoom));
            const y = sourceScreen.y + Math.max(10, Math.round(30 * this.zoom));
            line.push({ x: x, y: y, d: "right", duration:duration, type: e.type, session: e.session });
          } else if (e.x >= 0 && e.y >= 0 && !e.noheat) {
            const x = e.x * sourceScreen.w + sourceScreen.x;
            const y = e.y * sourceScreen.h + sourceScreen.y;
            line.push({ x: x, y: y, d: "right", duration: duration , type: e.type, session: e.session});
            lastDurationEvent = e
          }
          if (match && match.startPosition < i && match.endPosition >= i) {
            const point = line[line.length - 1];
            point.match = true
            matchLines.push(point);
          }
        } else {
          console.warn("_renderUserGraph()", "Screen is not there", e.screen);
        }
      }
      
      /** Since 4.0.60 we add a last node, if it was screen load */
      if (e && e.type === 'ScreenLoaded') {
        const screenID = this.getEventScreenId(e);
        const sourceScreen = this.sourceModel.screens[screenID];
        let x = Math.round(sourceScreen.x + sourceScreen.w / 2);
        let y = Math.round(sourceScreen.y + sourceScreen.h / 2);
        line.push({ x: x, y: y, d: "right", duration:duration, type: e.type, session: e.session});
        if (match && match.startPosition <=  sessionLength-1 && match.endPosition >= sessionLength-1) {
            const point = line[line.length - 1];
            point.match = true
            matchLines.push(point);
          }
      }

     
      // draw all points
      for (let i = 0; i < line.length; i++) {
        const p = line[i]
        const width = Math.round(40 * (p.duration / maxDuration)) + 25
        const [div, halo] = this._renderScreenEvent(p.x,p.y, p.type, "",db, p.session, width);
        if (i == line.length -1) {
          css.add(div, "MatcAnalyticCanvasEventSessionEnd");
          div.style.background = this.userJourneyEndColor
          halo.style.background = this.userJourneyEndColor + 28;
          halo.style.borderColor = this.userJourneyEndColor;
        } else if (i > 0) {
          if (p.match) {
            div.style.background = this.analyticParams.taskColor;
            halo.style.background = this.analyticParams.taskColor + 28;
            halo.style.borderColor = this.analyticParams.taskColor;
          } else {
            div.style.background = this.analyticParams.color
            halo.style.background = this.analyticParams.color + 28;
            halo.style.borderColor = this.analyticParams.color;
          }
        }
      }
  
      /**
       * Render successful task on top
       */
      if (task) {
        this.drawStraightAnalyticLine(session.session, line, this.analyticParams.color, 2, this.taskLineOpacity);
        this.drawStraightAnalyticLine(session.session, matchLines,this.analyticParams.taskColor, 4 ,this.taskLineOpacity);
      } else {
        this.drawStraightAnalyticLine(session.session,line, this.analyticParams.color, 2, this.taskLineOpacity);
      }

      return false;
    },

    drawDurationLine (session, line, defaultColor, maxDuration) {
      for (let i = 0; i < line.length-1; i++) {
        let start = line[i]
        let end = line[i+1]
        let p = end.duration / maxDuration
        let width = Math.round(p * 6) + 2
        let color = defaultColor
        if (!defaultColor) {
          //color = this.mixColor(Math.min(1, p))
        }
        this.drawStraightAnalyticLine(session,[start, end], color, width, this.taskLineOpacity);
      }
    },

    _renderScreenEvent(x, y, type, label, db, screenID, width, r = 15) {
      const cntr = db
        .div("MatcAnalyticCanvasEventCntr")
        .build(this.widgetContainer);
      cntr.style.left = Math.round(x) + "px";
      cntr.style.top = Math.round(y) + "px";


      const halo = db
        .div("MatcAnalyticCanvasEventHalo")
        .build(cntr);

      halo.style.width = width + "px";
      halo.style.height = width + "px";
      halo.style.top = -1 * Math.round(width / 2) + "px";
      halo.style.left = -1 * Math.round(width / 2) + "px";
 
      const div = db
        .div("MatcAnalyticCanvasEvent MatcAnalyticCanvasEvent" + type)
        .build(cntr);

      div.style.width = r + "px";
      div.style.height = r + "px";
      div.style.top = -1 * Math.round(r / 2) + "px";
      div.style.left = -1 * Math.round(r / 2) + "px";

      this.tempOwn(on(div, "click", lang.hitch(this, "onScreenEventClick", screenID)));

      return [div, halo];
    },

    onScreenEventClick(id, e) {
      this.stopEvent(e);
      if (this.toolbar) {
        this.toolbar.setSelectSessions([id]);
      }
    },

    _getSessionGraph(session, graph) {
  
      const sessionEvents = session.data;
      let from;
      let e = null
      let lastDurationEvent// = sessionEvents[0]
      let duration = 0
      for (let i = 0; i < sessionEvents.length; i++) {
        e = sessionEvents[i];
        // we start only counting durations once there
        // was an click event
        if (lastDurationEvent) {
          duration = e.time - lastDurationEvent.time
        }
        
      /**
         * Be aware of the overlay...
         */
        const screenID = this.getEventScreenId(e);
        const screen = this.sourceModel.screens[screenID];
        if (screen) {
          const to = {};
          if (e.type == "SessionStart") {
            to.x = screen.x - Math.max(10, Math.round(30));
            to.y = screen.y + Math.max(10, Math.round(30));
            from = this._addToGraph(from, to, graph, 0);
          } else if (e.x >= 0 && e.y >= 0 && !e.noheat) { // some click
            if (e.widget && this.sourceModel.widgets[e.widget]) {
              const widget = this.sourceModel.widgets[e.widget];
              to.x = Math.round(widget.x + widget.w / 2);
              to.y = Math.round(widget.y + widget.h / 2);
              from = this._addToGraph(from, to, graph, duration);
              lastDurationEvent = e
            } else {
              to.x = Math.round(Math.min(1, e.x) * screen.w + screen.x);
              to.y = Math.round(Math.min(1, e.y) * screen.h + screen.y);
              from = this._addToGraph(from, to, graph, duration);
              lastDurationEvent = e
            }
          }
        } else {
          console.warn("_getSessionGraph()", "Screen is not there", e.screen);
        }
      }

        /** Since 4.0.60 we add a last node, if it was screen load */
      if (e && e.type === 'ScreenLoaded') {
        const screenID = this.getEventScreenId(e);
        const screen = this.sourceModel.screens[screenID];
        const to = {
          x: Math.round(screen.x + screen.w / 2),
          y: Math.round(screen.y + screen.h / 2)
        }
        from = this._addToGraph(from, to, graph, duration);
      }


    },

    _addToGraph(from, to, graph, duration) {
      if (from) {
        const id = from.x + ";" + from.y + "-" + to.x + ";" + to.y;
        if (!graph[id]) {
          graph[id] = {
            from: from,
            to: to,
            count: 0,
            duration: 0
          };
        }
        graph[id].count++;
        graph[id].duration += duration * 1;
        return to;
      }
      return to;
    },

    /**********************************************************************
     * DropOff
     **********************************************************************/

    _render_global_DropOff(screenEvents, screen, ctx, div) {
      this.logger.log(-1, "_render_global_DropOff", "entry > ", this.analyticParams.task);
      this.setBW(true);
      if (this.analyticParams.task) {
        if (this.analyticParams.time) {
          this._render_dropoff_task_time(screenEvents, screen, ctx, div, this.analyticParams.task);
        } else {
          this._render_dropoff_task_success(screenEvents, screen, ctx, div, this.analyticParams.task);
        }
      } else {
        this.showError('No task selected')
      }
    },

     _render_dropoff_task_time (screenEvents, screen, ctx, div, task) {

      let db = new DomBuilder()

      var df = new DataFrame(this.events);
      var analytics  = new Analytics();
      let funnel = analytics.getFunnelSummary(df, task, this.annotation);



      let length = task.flow.length
      if (task.flow && task.flow.length > 1) {

        /**
         * We take here to total task time...
         */
        let maxTime = Math.max(1,funnel[funnel.length-1].durationMean)

        for (let i=0; i < task.flow.length - 1; i++){
          //let
          let startSummary = funnel[i+1]
          let endSummary = funnel[i+2]
          let start = task.flow[i]
          let end = task.flow[i+1]


          let time = endSummary.durationMean - startSummary.durationMean
          let p = Math.min(1, time  / maxTime)


          let startPos = this._getDropOffBoxPosition(start, i , length)
          let endPos = this._getDropOffBoxPosition(end, i+ 1, length)
          let line = [startPos, endPos]

          let color = this.mixColor(p)
          let width = Math.max(3, Math.round(this.dropOffLineWidth * p))
          this.drawAnalyticLine('dropOffLine'+i,line, color , width, this.taskLineOpacity);

          /**
           * Render Points
           */
          this._renderDropOffEvent(endPos.x, endPos.y, 'FlowStep', db, color, width + this.dropOffEventWidth, Math.round(time / 100) / 10, 's')
          if (i === 0) {
              color = this.mixColor(p)
              width = Math.max(3, Math.round(this.dropOffLineWidth * p))
                        console.debug(time, p, maxTime, width)
              this._renderDropOffEvent(startPos.x, startPos.y, 'FlowStep', db, color, width + this.dropOffEventWidth, '0', 's')
          }

        }
      } else {
        this.showError('Cannot show task times. The selected task has only one step.')
      }
    },

    _render_dropoff_task_success (screenEvents, screen, ctx, div, task) {

      let db = new DomBuilder()

      var df = new DataFrame(this.events);
      var analytics  = new Analytics();
      let funnel = analytics.getFunnelSummary(df, task, this.annotation);

      let length = task.flow.length
      if (task.flow && task.flow.length > 1) {
        for (let i=0; i < task.flow.length - 1; i++){
          //let
          let startSummary = funnel[i+1]
          let endSummary = funnel[i+2]
          let start = task.flow[i]
          let end = task.flow[i+1]

          let startPos = this._getDropOffBoxPosition(start, i, length)
          let endPos = this._getDropOffBoxPosition(end, i + 1, length)
          let line = [startPos, endPos]

          let color = this.greenToRed(endSummary.p)
          let width = Math.max(3, Math.round(this.dropOffLineWidth * endSummary.p))
          this.drawAnalyticLine('dropOffLine'+i,line, color , width, this.taskLineOpacity);

          /**
           * Render drop off
           */
          if (startSummary && endSummary) {
              let p = startSummary.p - endSummary.p
              let dropOffPos = {
                x: startPos.x + 100,
                y: startPos.y + 100
              }
              let dropOffLine = [startPos, dropOffPos]
               let width = Math.max(3, Math.round(this.dropOffLineWidth * p))
              this.drawAnalyticLine('dropOffLineDrop'+i,dropOffLine, this.dropOffLineColor , width, this.taskLineOpacity);
              this._renderDropOffEvent(dropOffPos.x, dropOffPos.y, 'FlowStep', db, this.dropOffLineColor, width + this.dropOffEventWidth, Math.round(-100 * p))
          }

          /**
           * Render points
           */
          this._renderDropOffEvent(endPos.x, endPos.y, 'FlowStep', db, color, width + this.dropOffEventWidth, Math.round(endSummary.p * 100))
          if (i === 0) {
              color = this.greenToRed(startSummary.p)
              width = Math.max(3, Math.round(this.dropOffLineWidth * startSummary.p))
              this._renderDropOffEvent(startPos.x, startPos.y, 'FlowStep', db, color, width + this.dropOffEventWidth, Math.round(startSummary.p * 100))
          }

        }
      } else {
        this.showError('Cannot show task times. The selected task has only one step.')
      }
    },

    _getDropOffBoxPosition (e, i = 0, l = 1) {

      if (e.widget) {
        let widget = this.sourceModel.widgets[e.widget]
        if (widget) {
            let pos = {}
            pos.x = Math.round(widget.x + widget.w / 2);
            pos.y = Math.round(widget.y + widget.h / 2);
            return pos
        } else {
           this.logger.warn("_geDropOffBoxPosition", "no widget > ", e.widget);
        }

      }
      if (e.screen) {
        let sourceScreen = this.sourceModel.screens[e.screen]
        if (sourceScreen) {
            let pos = {}
            pos.x = Math.round(sourceScreen.x + sourceScreen.w / 2);
            pos.y = Math.min(sourceScreen.y + sourceScreen.h, Math.round(sourceScreen.y + sourceScreen.h / 3) + (sourceScreen.h * i / (l * 2)));
            return pos
        } else {
           this.logger.warn("_geDropOffBoxPosition", "no screen > ", e.screen);
        }
      }
    },

    _renderDropOffEvent(x, y, type, db, color, width, p, unit='%') {
      var cntr = db
        .div("MatcAnalyticCanvasEventCntr")
        .build(this.widgetContainer);
      cntr.style.left = Math.round(x) + "px";
      cntr.style.top = Math.round(y) + "px";

      var div = db
        .div("MatcAnalyticCanvasEvent MatcAnalyticCanvasEvent" + type,)
        .build(cntr);

      var r = Math.max(5, Math.round(width));
      div.style.width = r + "px";
      div.style.height = r + "px";
      div.style.top = -1 * Math.round(r / 2) + "px";
      div.style.left = -1 * Math.round(r / 2) + "px";
      div.style.background = color

      if (unit) {
        db.span('MatcAnalyticCanvasEventLabel', p + unit).build(div)
      }

      return div;
    },


    /**********************************************************************
     * Gesture
     **********************************************************************/

    _render_global_Gesture() {
      this.logger.log(0, "_render_global_Gesture", "entry > ");

      var gestures = this.getGestures();
      var db = new DomBuilder();

      for (var i = 0; i < gestures.length; i++) {
        var e = gestures[i];
        var gesture = e.gesture;

        var screenID = this.getEventScreenId(e);
        var screen = this.sourceModel.screens[screenID];
        if (screen && gesture) {
          var line = [];

          var start = e.gesture.start;
          var end = e.gesture.end;
          if (start && end) {
            var x = start.x * screen.w + screen.x;
            var y = start.y * screen.h + screen.y;
            line.push({ x: x, y: y, d: "right" });

            this._renderGestureStart(x, y, this.analyticParams.color, db);

            x = end.x * screen.w + screen.x;
            y = end.y * screen.h + screen.y;
            line.push({ x: x, y: y, d: "right" });

            var r = Math.max(1, Math.round(3 * this.zoom));
            this.drawSVGLine("", line, this.analyticParams.color, r, 1);
          }
        } else {
          console.warn(
            "_render_global_Gesture()",
            "Screen is not there",
            e.screen
          );
        }
      }
    },

    _renderGestureStart(x, y, color, db) {
      var cntr = db
        .div("MatcAnalyticCanvasEventCntr")
        .build(this.widgetContainer);
      cntr.style.left = Math.round(x) + "px";
      cntr.style.top = Math.round(y) + "px";

      var div = db
        .div("MatcAnalyticCanvasEvent MatcAnalyticCanvasEvent")
        .build(cntr);
      var r = Math.max(5, Math.round(15 * this.zoom));
      div.style.width = r + "px";
      div.style.height = r + "px";
      div.style.top = -1 * Math.round(r / 2) + "px";
      div.style.left = -1 * Math.round(r / 2) + "px";
      div.style.backgroundColor = color;
      return div;
    },


    cleanUpAnalytics() {
			this.cleanUpAnalyticLines()

			this.cleanUpNode(this.widgetContainer)
      this.analyticsDivs = {};
    },

    /**********************************************************************
     * Analytic Cached method
     **********************************************************************/

    getGestures() {
      if (!this.cache["gestures"]) {
        var df = new DataFrame(this.events);
        var gestures = df.select("type", "in", [
          "ScreenGesture",
          "WidgetGesture",
        ]);
        this.cache["gestures"] = gestures.data;
      }

      return this.cache["gestures"];
    },

    getUserJourney() {
      if (!this.cache["userJourney"]) {
        var df = new DataFrame(this.events);
        df.sortBy("time");
        var sessionGroup = df.groupBy("session");
        let sessions = sessionGroup.data;
        this.cache["userJourney"] = sessions;
      }
      return this.cache["userJourney"];
    },

    getTaskPerformance() {
      if (!this.cache["taskPerformance"]) {
        const analytics = new Analytics();
        const df = new DataFrame(this.events);
        df.sortBy("time");

        const temp = analytics.getTaskPerformance(
          df, this.testSettings.tasks, false, false
        );
        const sessions = {};
        for (let i = 0; i < temp.data.length; i++) {
          const match = temp.data[i];
          if (!sessions[match.session]) {
            sessions[match.session] = {};
          }
          if (!sessions[match.session][match.task]) {
            sessions[match.session][match.task] = match;
          } else {
            console.warn("getTaskPerformance() Double mactch", match);
          }
        }
        this.cache["taskPerformance"] = sessions;
      }
      return this.cache["taskPerformance"];
    },

    getOverlayViews() {
      if (!this.cache["overlayViews"]) {
        var screenLoads = this.df.select("type", "==", "OverlayLoaded");

        var screenLoadCounts = screenLoads.count("overlay");
        var totalScreenLoads = screenLoads.size();

        var views = {};
        var screens = this.getScreens(this.sourceModel);
        for (var s = 0; s < screens.length; s++) {
          var screen = screens[s];
          views[screen.id] = screenLoadCounts.get(screen.id, null, 0);
        }

        this.cache["overlayViews"] = {
          total: totalScreenLoads,
          counts: views,
        };
      }
      return this.cache["overlayViews"];
    },

    getOverlayTest() {
      if (!this.cache["overlayTests"]) {
        var sessions = this.df.groupBy("session");
        var sessionCount = sessions.size().size();

        var tests = {};
        var screens = this.getScreens(this.sourceModel);
        for (var s = 0; s < screens.length; s++) {
          var screen = screens[s];
          tests[screen.id] = 0;
        }

        sessions.foreach(function (df) {
          var screenCounts = df.count("overlay"); // diference to screenTest
          screenCounts.foreach(function (row, id) {
            tests[id] += 1;
          });
        });

        this.cache["overlayTests"] = {
          sessions: sessionCount,
          counts: tests,
        };
      }
      return this.cache["overlayTests"];
    },

    getScreenViews() {
      if (!this.cache["screenViews"]) {
        var screenLoads = this.df.select("type", "==", "ScreenLoaded");
        var screenLoadCounts = screenLoads.count("screen");
        var totalScreenLoads = screenLoads.size();

        var views = {};
        var screens = this.getScreens(this.sourceModel);
        for (var s = 0; s < screens.length; s++) {
          var screen = screens[s];
          views[screen.id] = screenLoadCounts.get(screen.id, null, 0);
        }

        this.cache["screenViews"] = {
          total: totalScreenLoads,
          counts: views,
        };
      }
      return this.cache["screenViews"];
    },

    getScreenTests() {
      if (!this.cache["screenTests"]) {
        var sessions = this.df.groupBy("session");
        var sessionCount = sessions.size().size();

        var tests = {};
        var screens = this.getScreens(this.sourceModel);
        for (var s = 0; s < screens.length; s++) {
          var screen = screens[s];
          tests[screen.id] = 0;
        }

        sessions.foreach(function (df) {
          var screenCounts = df.count("screen");
          screenCounts.foreach(function (row, id) {
            tests[id] += 1;
          });
        });

        this.cache["screenTests"] = {
          sessions: sessionCount,
          counts: tests,
        };
      }
      return this.cache["screenTests"];
    },

    getScreenDwellTime() {
      if (!this.cache["screenDwell"]) {
        var count = this.df.count("session");
        var sessionCount = count.size();

        var analytics = new Analytics();
        var screenTimeGrouping = analytics.getScreenTimeGrouping(this.df);
        var totalTime = screenTimeGrouping.sum().sum();

        var times = {};
        var screens = this.getScreens(this.sourceModel);
        for (var s = 0; s < screens.length; s++) {
          var screen = screens[s];
          var screenTimeDF = screenTimeGrouping.get(screen.id);
          if (screenTimeDF) {
            times[screen.id] = screenTimeDF.sum();
          } else {
            times[screen.id] = 0;
          }
        }

        this.cache["screenDwell"] = {
          total: totalTime,
          times: times,
          sessions: sessionCount,
        };
      }
      return this.cache["screenDwell"];
    },

    getOverlayDwellTime() {
      if (!this.cache["overlayDwell"]) {
        var count = this.df.count("session");
        var sessionCount = count.size();

        var analytics = new Analytics();

        /**
         * We calculate the overlay time relative to the absolute screen time...
         */
        var screenTimeGrouping = analytics.getScreenTimeGrouping(this.df);
        var totalTime = screenTimeGrouping.sum().sum();

        var overlayGrouping = analytics.getOverlayTimeGrouping(this.df);

        var times = {};
        var screens = this.getScreens(this.sourceModel);
        for (var s = 0; s < screens.length; s++) {
          var screen = screens[s];
          var screenTimeDF = overlayGrouping.get(screen.id);
          if (screenTimeDF) {
            times[screen.id] = screenTimeDF.sum();
          } else {
            times[screen.id] = 0;
          }
        }

        this.cache["overlayDwell"] = {
          total: totalTime,
          times: times,
          sessions: sessionCount,
        };
      }
      return this.cache["overlayDwell"];
    },

    getScreenWidgetClicks() {
      if (!this.cache["screenWidgetClicks"]) {
        /**
         * FIXME: This could be nice with regards to the overlays....
         *
         * Some clicks should be attributed the to overlay, nit the clicks, or?
         */
        var widgetEvents = this.df.select("type", "==", "WidgetClick");
        var widgetScreenEvents = widgetEvents.count("screen");
        var totalWidgetEvents = widgetScreenEvents.sum();

        /**
         * Now filter out overlay events
         */
        widgetEvents = widgetEvents.select("overlay", "==", null);
        widgetScreenEvents = widgetEvents.count("screen");

        var clicks = {};
        var screens = this.getScreens(this.sourceModel);
        for (var s = 0; s < screens.length; s++) {
          var screen = screens[s];
          clicks[screen.id] = widgetScreenEvents.get(screen.id, null, 0);
        }

        this.cache["screenWidgetClicks"] = {
          clicks: clicks,
          total: totalWidgetEvents,
        };
      }

      return this.cache["screenWidgetClicks"];
    },


    getScreenClicksOnBackground() {
      if (!this.cache["screenClicksOnBackground"]) {
        var screenClicks = this.df.select("type", "==", "ScreenClick");
        this.cache["screenClicksOnBackground"] = screenClicks
      }

      return this.cache["screenClicksOnBackground"];
    },

    getMissedClicks () {
       if (!this.cache["missedClicks"]) {
        /** 
         * Get all screens that do not have a line
         */
        var screens = Object.values(this.sourceModel.screens);
        let passiveScreens = {}
        screens.forEach(s => {
          let linesFrom = this.getFromLines(s)
          if (linesFrom.length === 0) {
            passiveScreens[s.id] = true
          }
        })


        /**
         * Get all the widgets that do not have a line
         * AND that are not inputs 
         */
        let passiveWidgets = []
        let widgets = Object.values(this.sourceModel.widgets)
        widgets.forEach(w => {
          if (w.type === "Box" || w.type === "Button" || w.type === "HotSpot") {
            let linesFrom = this.getFromLines(w)
            if (linesFrom.length === 0) {
              passiveWidgets[w.id] = true
            }
          }
        })

        /**
         * Filter screenclicks for these screens
         */
        let clickEvents = this.df
          .select("type", "in", ["ScreenClick", "WidgetClick"])
          .as_array();
        

        let missedClicks = clickEvents.filter(e => {
          if (e.type === "ScreenClick" && passiveScreens[e.screen] === true) {
            return true
          }
          if (e.type === "WidgetClick" && passiveWidgets[e.widget] === true) {
            return true
          }
        
          return false
        })

        this.cache["missedClicks"] = missedClicks;
      }

      return this.cache["missedClicks"];
    },


    getScreenClicks() {
      if (!this.cache["screenClicks"]) {
        /**
         * FIXME: This could be nice with regards to the overlays....
         *
         * Some clicks should be attributed the to overlay, nit the clicks, or?
         */
        var clickEvents = this.df.select("type", "in", [
          "ScreenClick",
          "WidgetClick",
        ]);
        var clickEventsCount = clickEvents.count("screen");
        var totalWidgetEvents = clickEventsCount.sum();

        var widgetEvents = this.df.select("type", "==", "ScreenClick");
        var widgetScreenEvents = widgetEvents.count("screen");

        var clicks = {};
        var screens = this.getScreens(this.sourceModel);
        for (var s = 0; s < screens.length; s++) {
          var screen = screens[s];
          clicks[screen.id] = widgetScreenEvents.get(screen.id, null, 0);
        }

        this.cache["screenClicks"] = {
          clicks: clicks,
          total: totalWidgetEvents,
        };
      }

      return this.cache["screenClicks"];
    },

    getOverlayClicks() {
      if (!this.cache["overlayCicks"]) {
        /**
         * FIXME: This could be nice with regards to the overlays....
         *
         * Some clicks should be attributed the to overlay, nit the clicks, or?
         */
        var widgetEvents = this.df.select("type", "==", "ScreenClick");
        var widgetScreenEvents = widgetEvents.count("overlay");
        var totalWidgetEvents = widgetScreenEvents.sum();

        var clicks = {};
        var screens = this.getScreens(this.sourceModel);
        for (var s = 0; s < screens.length; s++) {
          var screen = screens[s];
          clicks[screen.id] = widgetScreenEvents.get(screen.id, null, 0);
        }

        this.cache["overlayCicks"] = {
          clicks: clicks,
          total: totalWidgetEvents,
        };
      }

      return this.cache["overlayCicks"];
    },

    getOverlayWidgetClicks() {
      if (!this.cache["overlayWidgetCicks"]) {
        /**
         * FIXME: This could be nice with regards to the overlays....
         *
         * Some clicks should be attributed the to overlay, nit the clicks, or?
         */
        var widgetEvents = this.df.select("type", "==", "WidgetClick");
        var widgetScreenEvents = widgetEvents.count("overlay");
        var totalWidgetEvents = widgetScreenEvents.sum();

        var clicks = {};
        var screens = this.getScreens(this.sourceModel);
        for (var s = 0; s < screens.length; s++) {
          var screen = screens[s];
          clicks[screen.id] = widgetScreenEvents.get(screen.id, null, 0);
        }

        this.cache["overlayWidgetCicks"] = {
          clicks: clicks,
          total: totalWidgetEvents,
        };
      }

      return this.cache["overlayWidgetCicks"];
    },

    getWidgetData() {
      if (!this.cache["widgetData"]) {
        var analytics = new Analytics();
        var widgets = {};
        var data = analytics.getWidgetStatistics(this.sourceModel, this.df);
        for (var id in data) {
          widgets[id] = data[id];
        }
        this.cache["widgetData"] = widgets;
      }
      return this.cache["widgetData"];
    },

    getFirstNClicksData(n) {
      var key = "firstClicks" + n;
      if (!this.cache[key]) {
        var analytics = new Analytics();
        this.cache[key] = analytics.getFirstNClicks(this.events, n);
      }
      return this.cache[key];
    },

    /**********************************************************************
     * DI
     **********************************************************************/

    setController(c) {
      this.logger.log(2, "setController", "enter");
      this.controller = c;
      c.setCanvas(this);
    },

    getController() {
      if (this._controllerCallback) {
        this[this._controllerCallback]();
      }
      return this.controller;
    },

    setControllerCallback(c) {
      this._controllerCallback = c;
    },

    setModelFactory(f) {
      this.logger.log(3, "setModelFactory", "enter");
      this.factory = f;
    },

    setRenderFactory(f) {
      this.logger.log(3, "setRenderFactory", "enter");
      this.renderFactory = f;
    },

    setModel(model) {
      this.sourceModel = model;
      this.grid = this.sourceModel.grid;
      this.loadComments();
    },

    setEvents(events) {
      this.logger.log(1, "setEvents", "enter > # " + events.length);
      var analytics = new Analytics();
      this.events = analytics.nornalizeContainerChildEvents(events);
      this.df = new DataFrame(events);
      this.df.sortBy("time");
      this.fixGestures(events);
    },

    setAnnotation(a) {
      this.logger.log(2, "setAnnotation", "enter > # ");
      this.annotation = a;
    },

    setTest(t) {
      this.logger.log(2, "setTest", "enter > # ");
      this.testSettings = t;
    },

    setAnalyticMode(mode, params) {
      this.logger.log(2, "setAnalyticMode", "entry > mode: " + mode);
      this.analyticMode = mode;
      this.analyticParams = params;
      this.rerender();

      if (this.analyticCSS) {
        css.remove(this.domNode, this.analyticCSS);
      }

      this.analyticCSS = mode;
      css.add(this.domNode, this.analyticCSS);
    },

    setUser(u) {
      this.user = u;
    },

    setMode(mode, forceRender) {
      this.logger.log(
        2,
        "setMode",
        "enter > " + mode + " != " + this.mode + " > " + forceRender
      );
      if (mode != this.mode) {
        this.mode = mode;
        if (this.toolbar) {
          this.toolbar.setMode(mode);
        }
        this.rerender();
      } else if (forceRender) {
        this.rerender();
      }
    },

    /***************************************************************************
     * Keyboard handling
     ***************************************************************************/

    onKeyPress(e) {
      this._currentKeyEvent = e;

      if (this.state == "simulate" || this.state == "dialog") {
        return;
      }

      var target = e.target;
      if (css.contains(target, "MatcIgnoreOnKeyPress")) {
        return;
      }

      /**
       * The keycode is differently in every browser!
       */
      var k = e.keyCode ? e.keyCode : e.which;

      if (k == 32) {
        // space

        if (!this._inlineEditStarted) {
          this.setMode("move");
          this.stopEvent(e);
          /**
           * start the dnd already
           */
          this.onDragStart(
            this.container,
            "container",
            "onCanvasDnDStart",
            "onCanvasDnDMove",
            "onCanvasDnDEnd",
            null,
            this._lastMouseMoveEvent
          );
        }

        /**
         * Arrow dispatch...
         */
      } else if (k == 37) {
        this.onArrowLeft();
      } else if (k == 39) {
        this.onArrowRight();
      } else if (k == 40) {
        this.onArrowDown();
      } else if (k == 38) {
        this.onArrowUp();
      } else if (k == 171 || k == 187) {
        // +

        if (!this._inlineEditStarted) {
          this.onClickPlus();
          this.stopEvent(e);
        }
      } else if (k == 173 || k == 189) {
        //-

        if (!this._inlineEditStarted) {
          this.onClickMinus();
          this.stopEvent(e);
        }
      }
    },

    onKeyUp(e) {
      var k = e.keyCode ? e.keyCode : e.which;
      if (k == 32) {
        this.onDragEnd(this._lastMouseMoveEvent);
        this.setMode("view");
      }

      delete this._currentKeyEvent;
    },

    /***************************************************************************
     * Helper Functons
     ***************************************************************************/

    initMouseTracker() {},

    onMouseMove(e) {
      var pos2 = this.getCanvasMousePosition(e, true);
      //this._debugMouseLabel.innerHTML = "[" + Math.round(pos2.x) +" , "+ Math.round(pos2.y) + "]";
      this._lastMousePos = pos2;
      this._lastMouseMoveEvent = e;
    },

    destroy() {
      this.cleanUp();
    },

    logPageView(url) {
      this.logger.log(4, "logPageView", "enter", url);
    },
  },
  mounted() {},
};
</script>